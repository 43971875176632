body,
html {
  height: 100%;
}

.va-title {
  font-size: 20px;
}

.va-heading1 {
  font-size: 14px;
  font-weight: bold;
}

.va-heading2 {
  font-size: 12px;
}

.va-heading3 {
  font-size: 12px;
}

.va-text {
  font-size: 12px;
}

.va-small-text {
  font-size: 11px;
}

body {
  font-family: "Arial" !important;
}

.navbar {
  min-height: 35px;
}

.toolbar-element {
  height: 2vh;
  min-height: 24px;
  cursor: pointer;
}

.toolbar-element-container {
  padding: 1px;
  margin-left: 4px;
  float: left;
}

#cy {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 10px;
  width: 100%;
  height: 100%;
  border: 2px solid #2f4154;
  border-radius: 6px;
  background: #ffffff;
}

app-navbar {
  grid-area: nb;
}

app-toolbar {
  grid-area: tb;
  padding-left: 10px;
}

app-cytoscape {
  grid-area: cy;
  position: relative;
}

app-operation-tabs {
  grid-area: ot;
  padding-left: 10px;
  overflow-y: scroll;
}

.root {
  display: grid;
  grid-template-areas: "nb nb" "tb tb" "cy ot" "ti ti";
  height: 99vh;
  grid-template-columns: 75% 25%;
  grid-template-rows: min-content min-content auto max-content;
}

.zoom-cursor {
  cursor: url(../src/assets/img/zoom-cursor.svg), pointer !important;
}

.filter-node-class {
  margin: 1%;
}

.filter-edge-class {
  color: #343a40;
  border-bottom: 2px solid #343a40;
  margin: 1%;
  padding: 3px;
  text-decoration: none;
}

.filter-edge-class:hover {
  background-color: #343a40;
  color: white;
  border-radius: 3px;
  text-decoration: none;
}

.filter-class-disabled {
  opacity: 0.4;
}

.filter-form {
  padding: 3px 20px 3px 5px;
}

.filter-input {
  padding-right: 5px;
}

#rule-elements {
  font-size: 0.875rem;
}

#filter-add-rule-btn {
  vertical-align: sub;
}

.logic-op-link {
  display: inline-block;
  border: 2px solid;
  border-radius: 5px;
  color: inherit;
  width: 3em;
}

.panel-heading {
  cursor: pointer;
  text-align: center;
  background-color: #eaeaea;
  line-height: 1.45;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.subpanel-heading {
  cursor: pointer;
  text-align: center;
  background-color: #eaeaea40;
  line-height: 1.45;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.panel-heading-hideable {
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #eaeaea;
}

.panel-title {
  font-size: 12px;
  color: #555555;
  vertical-align: middle;
  display: table-cell;
}

.panel-body {
  padding-top: 7px;
  padding-bottom: 3px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.panel {
  border: 2px solid transparent;
}

.accordion-toggle:after {
  font-family: "FontAwesome", sans-serif;
  content: "\f077";
  float: right;
  padding-right: 4px;
  color: inherit;
}

.panel-heading.collapsed .accordion-toggle:after {
  content: "\f078";
}

.subpanel-heading.collapsed .accordion-toggle:after {
  content: "\f078";
}

.cytoscape-navigator-wrapper {
  position: fixed;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border: 2px solid #2f4154;
  border-radius: 6px;
  background: #fff;
}

[data-bs-toggle="collapse"] .fa:before {
  content: "\f139";
}

[data-bs-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}

#highlight-search-input {
  width: 75px;
  border-radius: 5px;
  height: 23px;
}

.vall-ctx-menu-item:hover {
  color: #16181b !important;
  background-color: #f8f9fa !important;
}

.vall-ctx-menu-item {
  background-color: #ffffff !important;
  padding: 0.25rem 1.5rem !important;
  width: 100% !important;
  font-weight: 400 !important;
  color: #212529 !important;
}

.vall-ctx-menu {
  padding: 0.5rem 0 !important;
  margin: 0.125rem 0 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  background-color: #ffffff !important;
  border-radius: 0.25rem !important;
}

.modal-title {
  margin: 0 auto;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.img-icon {
  width: 20px;
  height: 20px;
}

.img-sm-icon {
  width: 12px;
  height: 12px;
}

.query-input {
  width: 60px;
  display: inline;
  margin-top: 2px;
}

.query-input-wide {
  width: 105px;
  display: inline;
  margin-top: 2px;
}

.custom-query {
  padding: 2%;
  color: black;
}

.cb-filter {
  margin-left: -1rem !important;
}

.strokeme {
  color: white;
  text-shadow: 1px 0px 1px black, 0px 1px 1px black, -1px 0px 1px black,
    0px -1px 1px black;
}

.tight-select {
  background-position: center right 5px;
  height: inherit;
}

.query-select {
  text-align: center;
  text-align-last: center;
  width: auto;
}

.input-3-char {
  width: 5.5em;
}

.pointer {
  cursor: pointer;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.standart-input-height {
  height: 2em;
  padding: 0.25rem !important;
}

.standart-input-height-sec {
  height: 2.25em;
}

.modal-dialog {
  width: fit-content;
  min-width: 380px;
}

.form-inline {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

label.form-check-label,
input.form-check-input {
  cursor: pointer;
}

rect[fill-opacity="0"]:not([stroke="none"]) {
  stroke: white;
  stroke-width: 2px;
}

.node-tooltip {
  overflow-wrap: break-word;
  padding-left: 3px;
  padding-right: 3px;
  background: rgba(255, 255, 255, 0.5);
  justify-content: left;
  align-items: left;
  color: black;
  border: 2px solid #585c66;
  border-radius: 2px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

.edge-tooltip {
  overflow-wrap: break-word;
  padding-left: 3px;
  padding-right: 3px;
  background: rgba(193, 202, 222, 0.5);
  justify-content: left;
  align-items: left;
  border: 2px solid #585c66;
  border-radius: 2px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

.node-tooltip-outer,
.edge-tooltip-outer {
  padding: 5px 0;
  margin: 0;
}

.edge-tooltip-inner-part {
  color: black;
}

.edge-CIGAR-M-Eq-X {
  color: #0099ff;
}

.edge-CIGAR-I {
  color: #009900;
}

.edge-CIGAR-D {
  color: #cc0000;
}

.edge-CIGAR-N {
  color: #ff9900;
}

.edge-CIGAR-S-H {
  color: #996633;
}

.table-tooltip {
  position: absolute;
  z-index: 1000;
  padding: 4px 3px 4px 5px;
  max-height: 350px;
  max-width: 300px;
  overflow-wrap: break-word;
  text-align: left;
  font-family: Inconsolata, monospace;
  font-weight: 700;
  overflow-y: auto;
  line-height: 20px;
  border: 2px solid #65676b;
  border-radius: 5px;
  background-color: #ffffff;
}

.w5-5 {
  width: 5.5em;
}